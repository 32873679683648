<template>
   <div class="stepper-wrapper mx-5 px-3">
      <div class="stepper-item">
        <div class="step-counter">1</div>
      </div>
      <div class="stepper-item">
        <div class="step-counter">2</div>
      </div>
      <div class="stepper-item">
        <div class="step-counter">3</div>
      </div>
    </div>
</template>

<script>
   export default {
      
   }
</script>

<style scoped>
.stepper-wrapper {
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #efefef;
  width: 100%;
  top: 15px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #efefef;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  border: #efefef 2px solid;
  margin-bottom: 6px;
}


.stepper-item.active .step-counter {
  background-color: #cdcdcd;
  color: #fff;
}

.stepper-item.active::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #cdcdcd;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}


</style>