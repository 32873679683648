import {alertService} from "@/services/alert/alert.service";
import {registerService} from "@/services/auth/register/register.service";
import ProgressBar from './progress-bar.component.vue'


export default {
    components: {
        ProgressBar
      },
    data() {
        return {
            data: {
                email: '',
                lang: this.$i18n.locale
            },
            error: {},
            config: {},
            success: false
        }
    },
    async mounted() {
        this.data.email = this.$route.params.email;
    },
    methods: {
        async register$() {
            alertService.loading();
            await registerService.register(this.data).then((response) => {
                this.success = true;
                alertService.close();
            }).catch((response) => {
                this.error = response;
                alertService.close();
            });
        },
    }

}
