import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class RegisterService {
    register(data) {
        promise =  api.client.post(config.end_point.auth.register, data);
        return promise;
    }
}

export const registerService = new RegisterService();
